/*home*/

a{
  text-decoration: none;
  color: var(--dark-blue)
}


.home {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: beige;
  height: 600px;
}

.home h1 {
  font-size: 50px;
  padding: 0.1em 1em;
}

.homeBtn {
  background-color: var(--white);
  border: 0;
  font-family: var(--texto);
  font-weight: bold;
  height: 60px;
  max-width: 400px;
  border-radius: 50px;
  font-size: 18px;
  padding: 1em 3em;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.homeBtn:hover {
  cursor: pointer;
  background-color: #d9d9d9;
}

/*Nosotros*/
.nosotros {
  display: flex;
  flex-direction: column;
  padding: 2em;
  background-color: var(--dark-blue);
  color: var(--white);
}

.nosotrosCopy h1 {
  font-size: 50px;
}
.nosotrosCopy h2 {
  font-family: var(--titulos);
  font-weight: 300;
  font-size: 30px;
}
.nosotrosCopy span {
  color: var(--medium-green);
}

.nosotrosCopy p {
  font-size: 20px;
  font-family: var(--sub-titulos);
}

.nosotrosImagenes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  padding: 1em;
}

.nosotrosImagen {
  background-color: var(--white);
  width: 300px;
  height: 300px;
}

/*Proyecto*/

.proyecto {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:2em;
  background-color: var(--main-green);
  gap: 2em;

}

.proyectoProductivo h1 {
  font-size: 60px;
  text-align: center;
}

.proyectoCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white);
  border-radius: 50px;
  padding: 1em;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}

.proyectoCardImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2em;
}

.proyectoCardImageRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.proyectoCardText {
  margin: 1em;
  line-height: 3.2em;
  padding: 1em;
}

.proyectoCardText h2 {
  font-size: 34px;
}


.proyectoImagen {
  width: 300px;
  height: 400px;
  background-image: url(../assets/images/rojo.png);
  background-size: cover;
  margin: 10px;
}

 .proyectoMaquillajeImage {
  width: 600px;
  height: 500px;
  background-image: url(../assets/images/rojo.png);
  background-size: cover;
  margin: 10px;
}



.verProductosBtn {
  background-color: var(--dark-blue);

  font-family: var(--texto);
  font-weight: bold;
  height: 60px;
  max-width: 400px;
  border-radius: 50px;
  font-size: 18px;
  padding: 1em 2em;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border: 0;

}

.verProductosBtn a {
  color: var(--white);
}

.verProductosBtn:hover {
  cursor: pointer;
  background-color: var(--blue);
}


.infoBtn {
  background-color: var(--white);
  color: #00bb2d;
  font-family: var(--texto);
  font-weight: bold;
  height: 60px;
  max-width: 400px;
  border-radius: 50px;
  font-size: 18px;
  padding: 1em 2em;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border: 0;
  display: flex;
  gap: 5px;
}

.infoBtn:hover {
  cursor: pointer;
  background-color: #d9d9d9;
}
.infoBtn img{
  max-width: 20px;
}

/* Media Queries para pantallas más pequeñas */
@media only screen and (max-width: 767px) {
  .proyectoCard {
    flex-direction: column;
    max-width: 400px;
  }

  .proyectoCardImage, .proyectoMaquillajeImage{
    max-width: 230px;
  }

  .proyectoCardImageRow{
    display:block;
  }

  .verProductosBtn, .infoBtn{
    font-size: 12px;

  }
}

/* Media Queries para pantallas más grandes */
@media only screen and (min-width: 768px) {
  .proyectoCard {
    flex-direction: row;
  }
  .proyectoCardImage {
    margin-top: 0;
  }
  .proyectoCardText {
    margin-left: 50px;
    text-align: left;
  }
}

/*Capacitacion*/
.capacitacion {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--dark-blue);
  padding: 2em;
}

.capacitacionImages {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.capacitacionImage {
  width: 100%;
  max-width: 300px;
  margin: 10px;
  border-radius: 50px;
}

.capacitacionText h1 {
  font-size: 55px;
}

.capacitacionText {
  margin: 0 4em;
  color: var(--white);
  text-align: center;
  line-height: 1.2em;
  font-size: 20px;
}




/*Investigacion*/

.investigacion {
  display: flex;
  padding: 2em;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  background-color: var(--main-green);
  gap: 2em;
}

.investigacionText h1 {
  font-size: 55px;
}

.investigacionText {
  color: black;
  line-height: 1em;
  font-size: 18px;
  margin: 1em;
}

.investigacionText span {
  color: var(--dark-blue);
  font-weight: bold;
}

.investigacionImage {
  max-width: 700px;
  min-height: 300px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
}

.investigacionMensaje {
  font-size: 16px;
  text-align: center;
  background-color: var(--dark-blue);
  color: var(--white);
  border-radius: 50px;
  padding: 0.3em;
}

@media only screen and (max-width: 768px) {
  .investigacionColumns {
    display: flex;
    flex-direction: column;

  }

  .investigacionImage{
    max-width: 250px;
  }
}

@media only screen and (min-width: 769px) {
  .investigacionColumns {
    display: flex;
    flex-direction: row;
    gap: 1em;
  }
}



/*Frases */
.frases {
  background-color: var(--dark-blue);
  padding: 1.1em;
}

.frases h1 {
  color: var(--white);
  text-align: center;
  font-size: 70px;
}


.frasesText {
  color: var(--white);
  font-size: 1.2rem;
}

.frasesImage {
  max-width: 700px;
  min-height: 300px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  margin: 2em;

}

@media only screen and (max-width: 768px) {
  .frasesColumns {
    display: flex;
    flex-direction: column;
 
  }

  .frasesImage{
    max-width: 280px;
  }
}

@media only screen and (min-width: 769px) {
  .frasesColumns {
    display: flex;
    flex-direction: row;
    gap: 1em;
  }
}

