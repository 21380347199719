@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Roboto:wght@300&family=Unna:wght@400;700&display=swap");

:root {
  /*Colores*/
  --blue: #a2cdf2;
  --dark-blue: #2b5375;
  --main-green: #8fbf97;
  --medium-green: #a7d9aa;
  --white: #f2f2f2;

  /*Fuentes*/
  --titulos: "Unna", serif;
  --sub-titulos: "Lato", serif;
  --texto: "Roboto", sans-serif;
}

h1 {
  font-family: var(--titulos);
}
