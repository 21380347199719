
.card {
    position: relative;
    width: 600px;
    height: 600px;
    overflow: hidden;
    border-radius: 50px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.4);
  }
  
  .card-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
  
  .card-text {
    position: absolute;
    bottom: 0;
    background: rgba(95, 85, 85, 0.5);
    color: white;
    max-width: 100%;
    padding: 10px;
    
  }
  .card-button {
    background-color:#d9d9d9;
    color: #00BB2D;
    border: 0;
    border-radius: 20px;
    margin: 10px;
    font-size: 16px;
    padding: 0.6em;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .card-button:hover {
    cursor:pointer;
    background-color: lightgreen;
    
  }
  
  
  .card-button img{
    max-height: 22px;
    
    
    
    
    
  }

  
/* Media Queries para pantallas más pequeñas */
@media only screen and (max-width: 767px) {
  .card {
    width: 300px;
    max-height: 350px;

  }


}
  