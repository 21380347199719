
.slider {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .prev,
  .next {
    background: rgb(219, 219, 219);
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .prev {
    margin-right: 1rem;
  }
  
  .next {
    margin-left: 1rem;
  }

  /* Media Queries para pantallas más pequeñas */
@media only screen and (max-width: 767px) {
  .slider {
    display:block

  }
  
  .prev,
  .next {
    background: rgb(219, 219, 219);
    border: 10px;
    padding: 0.5rem 2rem;
    border-radius: 0.25rem;
    font-size: 1.5rem;
    cursor: pointer;
    margin: 2rem 4.5rem;
  }
  

}